<template>
  <blockquote class="bg-primary text-secondary rounded p-4">
    <p class="font-semibold">
      {{ review.customer }}
    </p>
    <p v-if="review.date" class="text-sm">
      {{ $format.date(review.date) }}
    </p>
    <client-only>
      <star-rating
        inline
        :active-color="$site.colors.action.background"
        :inactive-color="$site.colors.content['background-2']"
        :show-rating="false"
        :read-only="true"
        :rating="(review.rating !== null) ? parseInt(review.rating) : ((review.recommend) ? 5 : 0)"
        :star-size="24"
        class="mb-3 mt-3"
      />
    </client-only>
    <p v-if="review.employee && review.service" class="text-muted mb-0">
      <small>{{ $t('general.serviceWithEmployee', {service: review.service, employee: review.employee}) }}</small>
    </p>
    <p v-else-if="review.service" class="text-muted mb-0">
      <small>{{ review.service }}</small>
    </p>
    <div class="mt-2 mb-0">
      <b-truncate
        v-if="review.comment"
        :clamp="$t('general.more')"
        :less="$t('general.showLess')"
        :text="review.comment"
        :length="80"
        action-class="text-sm block mt-2 font-semibold"
        rounded-corners
      />
    </div>
    <!--<div class="text-muted font-italic">
      <p class="mb-0">
        <small style="font-size: 0.8rem">{{ review.customer }}</small>
      </p>
    </div>-->

    <div v-if="review.reply" class="speech-bubble position-relative bg-secondary rounded p-3 mt-3">
      <p class="text-muted font-weight-bold mb-0" style="font-size: 1rem">
        <small>Reply from {{ $site.location.title }}</small>
      </p>
      <p class="mb-0" style="font-size: 1rem">
        {{ review.reply }}
      </p>
    </div>
  </blockquote>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
      required: true
    }
  }
}
</script>
